import axios from "axios";

export const GetContent = async (serial, setter) => {
  await axios
    .get(
      "https://qrc-api.syncrogames.com/MatrixCodes/ticketsSerials?serialNumber=" +
        `${serial}`
    )
    .then((response) => setter(response.data));
};
