import "./App.css";
import React, { useEffect, useState } from "react";
import { GetContent } from "./ApiCall";
function App() {
  const qParams = new URLSearchParams(window.location.search);
  const [serial, setSerial] = useState(null);

  const [response, setResponse] = useState(null);
  useEffect(() => {
    let xx = qParams.get("serialNumber");
    if (xx != null) setSerial(xx);
  }, []);

  useEffect(() => {
    if (serial != null) {
      GetContent(serial, setResponse);
    }
  }, [serial]);

  useEffect(() => {
    console.log(response);
  }, [response]);

  const resolveStatsClass = (statCode) => {
    if (statCode === 0) return "qr_stare wred";
    if (statCode === 1) return "qr_stare wgreen";
    if (statCode === 2) return "qr_stare wred";
    if (statCode === 3) return "qr_stare wred";
    if (statCode === 4) return "qr_stare wred";
    return "qr_stare";
  };

  const formatUTCtoLocale = (utcString) => {
    let ddd = new Date(utcString);
    let day;
    day =
      ddd
        .toLocaleString("EN-en", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);

    let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
    let month =
      ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
    let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
    let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
    return day + " " + dayNum + "." + month + " - " + hour + ":" + mins;
  };

  const resolveImgSrc = (code) => {
    if (code === "GH") return "./gh.png";
    if (code === "RT") return "./rt.png";
    if (code === "KN") return "./kn.png";
    if (code === "SX") return "./sx.png";
    if (code === "SP") return "./sp.png";
    if (code === "LT") return "./lt.png";
  };

  const resolveAnotherClassName = (actual, elem) => {
    if (elem.status > 0) return actual + " line_green";
    else return actual + " line_red";
  };

  const conditionalRender = () => {
    if (response.content === null) {
      return <h1 style={{ textAlign: "center" }}>Ticket Serial Not Found</h1>;
    } else
      return (
        <div className="App">
          <div className="app_wrap">
            {/* <div>xxx</div> */}
            <br />
            <div
              className="qr_redirecter"
              onClick={() =>
                (window.location.href = response.content.redirectUri)
              }
            >
              Inapoi
            </div>
            <div className={resolveStatsClass(response.content.status)}>
              {response.content.statusDesc}
            </div>
            <div className="bet_right_serial">
              {response.content.ticketSerial}
            </div>
            <div className="qr_bet_details">
              <div className="bet_left">
                <img
                  src={resolveImgSrc(response.content.typeCode)}
                  className="bet_img_type"
                />
              </div>
              <div className="bet_right">
                <div className="bet_right_data">
                  Data emiterii:
                  <span style={{ float: "right" }}>
                    {formatUTCtoLocale(response.content.issueDate)}
                  </span>
                </div>
                <div className="bet_right_stake">
                  Suma incasata:{" "}
                  <span style={{ float: "right" }}>
                    {response.content.totalPaidAmount}{" "}
                    {response.content.currency}
                  </span>
                </div>
                <div className="bet_right_tax">
                  Taxe retinute:{" "}
                  <span style={{ float: "right" }}>
                    {response.content.taxAmount} {response.content.currency}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="qr_lines">
              {response.content.ticketLines.map((element, key) => {
                return (
                  <div className="line_root">
                    <div
                      className={resolveAnotherClassName("line_line1", element)}
                    >
                      {element.marketName}{" "}
                      <span>
                        {element.wins > 0 && (
                          <>
                            {element.wins} {response.content.currency}
                          </>
                        )}
                      </span>
                    </div>
                    <div
                      className={resolveAnotherClassName("line_line2", element)}
                    >
                      {element.selection}{" "}
                      <span>
                        {element.stake} {response.content.currency}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <br />
            {response.content.winsTotal > 0 && (
              <div className="qr_bet_details">
                <div className="bet_left">
                  <img src="./money.png" className="bet_img_type moneybag" />
                </div>
                <div className="bet_right">
                  <div className="bet_win_amount">
                    {response.content.winsTotal}{" "}
                    <span>{response.content.currency}</span>
                  </div>
                  <div className="bet_brut">
                    Castig brut:{" "}
                    <span>
                      {response.content.winsNet} {response.content.currency}
                    </span>
                  </div>
                  <div className="bet_impozit">
                    Impozit retinut:{" "}
                    <span>
                      {response.content.winsTax} {response.content.currency}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* <div
              className="qr_redirecter"
              onClick={() =>
                (window.location.href = response.content.redirectUri)
              }
            >
              Inapoi
            </div> */}
          </div>
        </div>
      );
  };

  return response && <>{conditionalRender()}</>;
}

export default App;
